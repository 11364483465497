var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "margin-top": "100px",
      "margin-bottom": "100px"
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "mb-2 text-dark",
    staticStyle: {
      "/* body/18 Reguler */\n\n      font-family": "'General Sans'",
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "18px",
      "line-height": "27px",
      "/* identical to box height, or 150% */\n\n      display": "flex",
      "align-items": "center",
      "/* text/secondary */\n\n      color": "#5a5a5a",
      "/* Inside auto layout */\n\n      flex": "none",
      "order": "1",
      "flex-grow": "0"
    },
    attrs: {
      "align": "center"
    }
  }, [_vm._v(" Alasan Kenapa Kamu Harus Ikut Try Out di ELP ")]), _c('b-col', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "3",
      "xl": "3"
    }
  }, [_c('b-card', {
    staticClass: "mb-2",
    staticStyle: {
      "background": "rgba(5, 255, 5, 0.19)"
    },
    attrs: {
      "text-variant": "#fffff"
    }
  }, [_c('b-card-text', [_c('feather-icon', {
    staticClass: "mb-1 float-left",
    staticStyle: {
      "color": "rgb(31, 148, 31)"
    },
    attrs: {
      "size": "24",
      "icon": "ShoppingBagIcon"
    }
  }), _c('br'), _c('h5', {
    staticClass: "text-left mt-2",
    staticStyle: {
      "color": "black"
    }
  }, [_c('b', [_vm._v("Paket Soal Akurat")])]), _c('h6', {
    staticClass: "text-left"
  }, [_vm._v(" Amet minim mollit non desaurent iulasdm alsdamoo ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "xl": "3"
    }
  }, [_c('b-card', {
    staticClass: "mb-2",
    staticStyle: {
      "background": "rgba(12, 93, 179, 0.139)"
    },
    attrs: {
      "text-variant": "#fffff"
    }
  }, [_c('b-card-text', [_c('feather-icon', {
    staticClass: "mb-1 float-left",
    staticStyle: {
      "color": "rgb(10, 105, 189)"
    },
    attrs: {
      "size": "24",
      "icon": "CalendarIcon"
    }
  }), _c('br'), _c('h5', {
    staticClass: "text-left mt-2",
    staticStyle: {
      "color": "black"
    }
  }, [_c('b', [_vm._v("Atur Ulang Jadwal")])]), _c('h6', {
    staticClass: "text-left"
  }, [_vm._v(" Amet minim mollit non desaurent iulasdm alsdamoo ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "xl": "3"
    }
  }, [_c('b-card', {
    staticClass: "mb-2",
    staticStyle: {
      "background": "rgba(255, 208, 0, 0.294)"
    },
    attrs: {
      "text-variant": "#fffff"
    }
  }, [_c('b-card-text', [_c('feather-icon', {
    staticClass: "mb-1 float-left",
    staticStyle: {
      "color": "rgb(233, 193, 13)"
    },
    attrs: {
      "size": "24",
      "icon": "GlobeIcon"
    }
  }), _c('br'), _c('h5', {
    staticClass: "text-left mt-2",
    staticStyle: {
      "color": "black"
    }
  }, [_c('b', [_vm._v("Kerjakan Di Mana Saja")])]), _c('h6', {
    staticClass: "text-left"
  }, [_vm._v(" Amet minim mollit non desaurent iulasdm alsdamoo ")])], 1)], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "xl": "3"
    }
  }, [_c('b-card', {
    staticClass: "mb-2",
    staticStyle: {
      "background": "rgba(219, 9, 219, 0.178)"
    },
    attrs: {
      "text-variant": "#fffff"
    }
  }, [_c('b-card-text', [_c('feather-icon', {
    staticClass: "mb-1 float-left",
    staticStyle: {
      "color": "purple"
    },
    attrs: {
      "size": "24",
      "icon": "BarChartIcon"
    }
  }), _c('br'), _c('h5', {
    staticClass: "text-left mt-2",
    staticStyle: {
      "color": "black"
    }
  }, [_c('b', [_vm._v("Grafik Progress")])]), _c('h6', {
    staticClass: "text-left"
  }, [_vm._v(" Amet minim mollit non desaurent iulasdm alsdamoo ")])], 1)], 1)], 1)], 1)], 1)], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "text-primary",
    attrs: {
      "align": "center"
    }
  }, [_c('b', [_vm._v("Keuntungan Ecourse Belajar.link?")])]);
}]

export { render, staticRenderFns }