var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {}, [_c('b-carousel', {
    staticStyle: {
      "text-shadow": "0px 0px 2px #000",
      "margin-bottom": "20px"
    },
    attrs: {
      "id": "carousel-fade",
      "fade": "",
      "indicators": ""
    }
  }, _vm._l(_vm.banner, function (item, i) {
    return _c('a', {
      attrs: {
        "href": item.link,
        "target": "_blank"
      }
    }, [_c('b-carousel-slide', {
      key: i,
      staticStyle: {
        "background-size": "100%",
        "height": "300px",
        "object-fit": "cover"
      },
      attrs: {
        "img-src": _vm.apiFile + item.image
      }
    })], 1);
  }), 0)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }