var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.userData ? _c('li', {
    staticClass: "li dropdown dropdown-right"
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_c('b-avatar', {
    staticClass: "badge-minimal",
    attrs: {
      "size": "40",
      "src": _vm.userData.photo ? _vm.apiFile + _vm.userData.photo.file : null,
      "variant": "light-primary",
      "badge": "",
      "badge-variant": "success"
    }
  }, [!_vm.userData.name ? _c('feather-icon', {
    attrs: {
      "icon": "UserIcon",
      "size": "22"
    }
  }) : _vm._e()], 1)], 1), _c('ul', {
    staticClass: "ul"
  }, [_c('li', {
    staticClass: "li"
  }, [_c('a', {
    staticClass: "text-primary",
    on: {
      "click": _vm.dashboard
    }
  }, [_vm._v("Dashboard")])]), _c('li', {
    staticClass: "li"
  }, [_c('a', {
    staticClass: "text-primary",
    on: {
      "click": _vm.logout
    }
  }, [_vm._v("Logout")])])])]) : _c('li', [_c('section', {
    staticClass: "d-flex align-items-center justify-content-center"
  }, [_c('a', {
    staticClass: "btn btn-white mx-1",
    staticStyle: {
      "background": "white",
      "border": "1px solid #cdcdcd"
    },
    on: {
      "click": _vm.daftar
    }
  }, [_vm._v(" Daftar ")]), _c('a', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.masuk
    }
  }, [_vm._v(" Masuk ")])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }