var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('b-overlay', {
    attrs: {
      "show": _vm.loading
    }
  }, [_c('b-col', [_c('h3', {
    staticClass: "mt-4 mb-3 text-primary",
    attrs: {
      "align": "center",
      "text-color": "primary"
    }
  }, [_c('b', [_vm._v(" Pilih Paket Try Out Sekarang ")])]), _c('b-row', _vm._l(_vm.data, function (item, i) {
    return _c('b-col', {
      key: i,
      attrs: {
        "md": "6",
        "xl": "3"
      }
    }, [_c('b-card', {
      staticClass: "mb-2 text-left",
      staticStyle: {
        "cursor": "pointer"
      },
      attrs: {
        "img-src": item.thumbnail != null ? _vm.apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg'),
        "img-top": "",
        "img-alt": "card img",
        "sub-title": item.sum_subtest_content + " subtes"
      },
      on: {
        "click": function click($event) {
          return _vm.detail(item);
        }
      }
    }, [_c('b-card-text', [_c('b', {
      staticClass: "text-dark"
    }, [_vm._v(_vm._s(item.name))])]), _c('div', {
      staticClass: "card__arrow",
      style: 'background:' + _vm.getColor(item.category.color)
    })], 1)], 1);
  }), 1), _c('center', [_c('b-button', {
    staticClass: "mt-1 mb-2",
    attrs: {
      "variant": "outline-primary",
      "to": {
        name: 'paket-selengkapnya'
      }
    }
  }, [_vm._v(" Selengkapnya ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }