var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    attrs: {
      "id": "card-images"
    }
  }, [_c('br'), _vm._m(0), _c('center', [_c('h4', {
    staticClass: "mb-2",
    staticStyle: {
      "width": "80%"
    },
    attrs: {
      "align": "center",
      "text-color": "primary"
    }
  }, [_vm._v(" Ulasan dari Para Alumni yang Sukses Setelah Mengikuti Try Out di ELP ")])]), _c('br'), _c('center', [_c('div', {
    staticStyle: {
      "width": "85%"
    }
  }, [_c('swiper', {
    staticClass: "swiper-responsive-breakpoints",
    attrs: {
      "options": _vm.swiperOptions,
      "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr'
    }
  }, [_vm._l(_vm.swiperData, function (data, index) {
    return _c('swiper-slide', {
      key: index
    }, [_c('b-card', [_c('b-card-body', [_c('center', [_c('b-avatar', {
      staticClass: "mb-2",
      attrs: {
        "size": "45"
      }
    }), _c('h6', {
      staticClass: "font-weight-bolder mb-25"
    }, [_vm._v(" Kidam Kusnandi ")]), _c('span', {}, [_vm._v("SMK Assalaam")])], 1), _c('b-card-text', {
      staticClass: "mt-1 text-left mt-2"
    }, [_vm._v(" Lulus Ujian CPNS ")]), _c('b-form-rating', {
      staticClass: "float-left",
      attrs: {
        "value": "5",
        "no-border": "",
        "readonly": "",
        "variant": "warning",
        "inline": "",
        "size": "sm"
      }
    }), _c('br'), _c('b-card-text', {
      staticClass: "mt-1 text-left"
    }, [_vm._v(" Some quick example text to build on the card title and make up the bulk of the card's content. ")])], 1)], 1)], 1);
  }), _c('div', {
    staticClass: "swiper-pagination mt-4",
    attrs: {
      "slot": "pagination"
    },
    slot: "pagination"
  })], 2)], 1)])], 1);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('h3', {
    staticClass: "mt-3 mb-2 text-primary",
    attrs: {
      "align": "center",
      "text-color": "primary"
    }
  }, [_c('b', [_vm._v("Kata Mereka")])]);
}]

export { render, staticRenderFns }