<template>
  <div v-if="Object.keys(profileData).length" id="user-profile">
    <nav class="header-navbar align-items-center navbar-brand-center navbar-fixed">
      <slot name="navbar" class="mt-1">
        <profile-header :header-data="profileData.header" />
        <!-- <navbar /> -->
      </slot>
    </nav>
    <header-satu />
    <profile-slider />
    <!-- profile info  -->
    <section id="profile-info">
      <!--/ load more  -->
      <!-- <b-row> -->

      <div class="container" style="width: 87%">
        <mengapa-einstein />
        <paket-paket />
        <br />
      </div>
      <!-- <div class="containerkatamereka"> -->
      <div class="" style="background: #f2ebe6">
        <!-- <div > -->
        <kata-mereka class="mt-2 mb-4" style="background: #f2ebe6" />
        <br />
        <br />
        <!-- </div> -->
      </div>
      <footer-home />
      <!-- </b-row> -->
    </section>
    <!--/ profile info  -->
  </div>
</template>

<script>
import { BRow, BCol } from "bootstrap-vue";

import ProfileHeader from "./components/Navbar.vue";
import FooterHome from "./components/Footer.vue";
import KataMereka from "./utama/KataMereka.vue";
import PaketPaket from "./utama/PaketPaket.vue";
import MengapaEinstein from "./utama/MengapaEinstein.vue";
import ProfileSlider from "./utama/ProfileSlider.vue";
import HeaderSatu from "./utama/HeaderSatu.vue";
/* eslint-disable global-require */
export default {
  components: {
    HeaderSatu,
    BRow,
    BCol,
    FooterHome,
    KataMereka,
    PaketPaket,
    MengapaEinstein,
    ProfileSlider,
    ProfileHeader,
  },
  data() {
    return {
      profileData: {},
      bannerData: {},
    };
  },
  method: {},
  created() {
    this.$http.get("/profile/data").then((res) => {
      this.profileData = res.data;
    });
  },
};
/* eslint-disable global-require */
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-profile.scss";
</style>
<style>
#user-profile {
  background-image: url("~@/assets/images/elp/bg-header.png");
  background-repeat: no-repeat;
  background-size: 100%;
}
.containerhome {
  max-width: 100%;
  padding: 56px 120px 80px 120px;
  position: relative;
  box-sizing: border-box;
}
.containerkatamereka {
  max-width: 100%;
  padding: 0px 120px 0px 120px;
  position: relative;
  box-sizing: border-box;
}
</style>
