<template>
  <div class="" style="margin-top: 100px; margin-bottom: 100px">
    <h3 class="text-primary" align="center">
      <b>Keuntungan Ecourse Belajar.link?</b>
    </h3>
    <div
      class="mb-2 text-dark"
      align="center"
      style="
        /* body/18 Reguler */

        font-family: 'General Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 27px;
        /* identical to box height, or 150% */

        display: flex;
        align-items: center;

        /* text/secondary */

        color: #5a5a5a;

        /* Inside auto layout */

        flex: none;
        order: 1;
        flex-grow: 0;
      "
    >
      Alasan Kenapa Kamu Harus Ikut Try Out di ELP
    </div>
    <b-col>
      <b-row>
        <b-col md="3" xl="3">
          <b-card
            class="mb-2"
            style="background: rgba(5, 255, 5, 0.19)"
            text-variant="#fffff"
          >
            <b-card-text>
              <feather-icon
                class="mb-1 float-left"
                style="color: rgb(31, 148, 31)"
                size="24"
                icon="ShoppingBagIcon"
              />
              <br />
              <h5 class="text-left mt-2" style="color: black">
                <b>Paket Soal Akurat</b>
              </h5>
              <h6 class="text-left">
                Amet minim mollit non desaurent iulasdm alsdamoo
              </h6>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" xl="3">
          <b-card
            class="mb-2"
            style="background: rgba(12, 93, 179, 0.139)"
            text-variant="#fffff"
          >
            <b-card-text>
              <feather-icon
                class="mb-1 float-left"
                style="color: rgb(10, 105, 189)"
                size="24"
                icon="CalendarIcon"
              />
              <br />
              <h5 class="text-left mt-2" style="color: black">
                <b>Atur Ulang Jadwal</b>
              </h5>
              <h6 class="text-left">
                Amet minim mollit non desaurent iulasdm alsdamoo
              </h6>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" xl="3">
          <b-card
            class="mb-2"
            style="background: rgba(255, 208, 0, 0.294)"
            text-variant="#fffff"
          >
            <b-card-text>
              <feather-icon
                class="mb-1 float-left"
                style="color: rgb(233, 193, 13)"
                size="24"
                icon="GlobeIcon"
              />
              <br />
              <h5 class="text-left mt-2" style="color: black">
                <b>Kerjakan Di Mana Saja</b>
              </h5>
              <h6 class="text-left">
                Amet minim mollit non desaurent iulasdm alsdamoo
              </h6>
            </b-card-text>
          </b-card>
        </b-col>
        <b-col md="3" xl="3">
          <b-card
            class="mb-2"
            style="background: rgba(219, 9, 219, 0.178)"
            text-variant="#fffff"
          >
            <b-card-text>
              <feather-icon
                class="mb-1 float-left"
                style="color: purple"
                size="24"
                icon="BarChartIcon"
              />
              <br />
              <h5 class="text-left mt-2" style="color: black">
                <b>Grafik Progress</b>
              </h5>
              <h6 class="text-left">
                Amet minim mollit non desaurent iulasdm alsdamoo
              </h6>
            </b-card-text>
          </b-card>
        </b-col>
      </b-row>
    </b-col>
  </div>
</template>

<script>
import { BLink } from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTable,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BTable,
    BFormGroup,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: ["first_name", "last_name", "age"],
      items: [],
      img: require("@/assets/images/logo/logo.jpg"),
    };
  },
};
</script>
