<template>
  <section id="card-images">
    <b-overlay :show="loading">
      <b-col>
        <h3 class="mt-4 mb-3 text-primary" align="center" text-color="primary">
          <b> Pilih Paket Try Out Sekarang </b>
        </h3>
        <b-row>
          <!-- image top -->
          <b-col md="6" xl="3" v-for="(item, i) in data" :key="i">
            <b-card
              style="cursor: pointer"
              @click="detail(item)"
              :img-src="item.thumbnail != null ? apiFile + item.thumbnail : require('@/assets/images/elp/no-image.jpg')"
              img-top
              img-alt="card img"
              :sub-title="item.sum_subtest_content + ` subtes`"
              class="mb-2 text-left"
            >
              <b-card-text>
                <b class="text-dark">{{ item.name }}</b>
              </b-card-text>
              <div class="card__arrow" :style="'background:' + getColor(item.category.color)"></div>
            </b-card>
          </b-col>
        </b-row>
        <center>
          <b-button variant="outline-primary" class="mt-1 mb-2" :to="{ name: 'paket-selengkapnya' }"> Selengkapnya </b-button>
        </center>
      </b-col>
    </b-overlay>
  </section>
</template>

<script>
import { BOverlay, BRow, BCol, BCard, BCardText, BCardTitle, BButton, BLink } from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { isRef } from "@vue/composition-api";

export default {
  components: {
    BOverlay,
    BButton,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BLink,
  },

  data() {
    return {
      loading: false,
      data: [],
    };
  },

  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
  },

  methods: {
    getColor(color) {
      let warna = "";
      if (color == "primary") {
        warna = "blue";
      } else if (color == "success") {
        warna = "green";
      } else if (color == "danger") {
        warna = "red";
      } else if (color == "info") {
        warna = "light-blue";
      } else if (color == "warning") {
        warna = "yellow";
      }
      return warna;
    },
    getData() {
      this.loading = true;
      let params = {
        orderCol: "price",
        order: "asc",
      };
      this.$store
        .dispatch("paketTryout/index", params)
        .then((res) => {
          this.loading = false;
          this.data = res.data.data.slice(0, 4);
        })
        .catch((err) => {
          this.loading = false;
          this.$toast({
            component: ToastificationContent,
            position: "top-right",
            props: {
              title: `Error `,
              icon: "XIcon",
              variant: "danger",
              text: err.response.data.message,
            },
          });
        });
    },
    detail(item) {
      window.location = `paket-detail/${item.slug}`;
    },
  },

  mounted() {
    this.getData();
  },
};
</script>

<style>
.card .card__arrow {
  position: absolute;

  padding: 20px;
  border-top-left-radius: 50px;
  border-bottom-right-radius: 10px;
  bottom: 0;
  right: 0;
  transition: 0.2s;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>
