var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "mt-2"
  }, [_c('b-card-group', [_c('b-card', {
    attrs: {
      "no-body": "",
      "bg-variant": "primary",
      "text-variant": "white"
    }
  }, [_c('div', {
    staticClass: "text-left mt-4 mb-4 container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('b-col', [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "xl": "5"
    }
  }, [_c('b-row', {
    staticClass: "mb-2 text-white",
    attrs: {
      "align-v": "baseline"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "2",
      "md": "3"
    }
  }, [_c('b-img', {
    attrs: {
      "src": _vm.img,
      "alt": "",
      "rounded": "",
      "width": "80px"
    }
  })], 1), _c('b-col', {
    attrs: {
      "md": "9"
    }
  }, [_c('div', {
    staticStyle: {
      "font-size": "1.625em"
    }
  }, [_c('b', [_vm._v("Belajar.link")])])])], 1), _c('b-row', {
    staticClass: "mb-1 text-white"
  }, [_c('b-col', [_c('div', {
    staticStyle: {
      "font-size": "1em"
    }
  }, [_vm._v(" This card has supporting text below as a natural lead-in to additional content. ")])])], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "xl": "2"
    }
  }, [_c('div', {
    staticClass: "mb-2 text-white",
    staticStyle: {
      "font-size": "1em"
    }
  }, [_c('b', [_vm._v("Kontak")])]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('feather-icon', {
    attrs: {
      "icon": "PhoneIcon"
    }
  }), _vm._v(" +62 8888 888 8888 ")], 1)], 1), _c('b-row', [_c('b-col', [_c('feather-icon', {
    attrs: {
      "icon": "MailIcon"
    }
  }), _vm._v(" belajarlink@gmail.com ")], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "xl": "2"
    }
  }, [_c('div', {
    staticClass: "mb-2 text-white",
    staticStyle: {
      "font-size": "1em"
    }
  }, [_c('b', [_vm._v("Layanan & Informasi")])]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        name: 'tentang-kami'
      }
    }
  }, [_vm._v(" Tentang Kami ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        name: 'kebijakan-privasi'
      }
    }
  }, [_vm._v(" Kebijakan Privasi ")])], 1)], 1), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_c('b-link', {
    staticClass: "text-white",
    attrs: {
      "to": {
        name: 'syarat-ketentuan'
      }
    }
  }, [_vm._v(" Syarat & Ketentuan ")])], 1)], 1)], 1), _c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "md": "6",
      "xl": "3"
    }
  }, [_c('div', {
    staticClass: "mb-2 text-white",
    staticStyle: {
      "font-size": "1em"
    }
  }, [_c('b', [_vm._v("Alamat")])]), _c('b-row', {
    staticClass: "mb-1"
  }, [_c('b-col', [_vm._v("Jl. Belimbing No.13, ihapit, Kec. Bandung Wetan")])], 1)], 1)], 1)], 1)], 1)])], 1), _c('div', {
    staticClass: "bg-white p-2"
  }, [_c('div', {
    staticClass: "container",
    staticStyle: {
      "width": "90%"
    }
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-9"
  }, [_c('h6', {
    staticClass: "text-left"
  }, [_c('b', [_vm._v("© " + _vm._s(new Date().getFullYear()) + " Belajar.link")]), _c('span', {
    staticClass: "d-none d-sm-inline-block"
  }, [_vm._v(" . All rights Reserved ")])])]), _c('div', {
    staticClass: "col-lg d-none d-md-block d-md-none d-lg-block"
  }, [_c('h6', {
    staticClass: "text-left"
  }, [_c('b', [_vm._v("Ikuti Kami")]), _c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "FacebookIcon",
      "size": "18"
    }
  }), _c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "TwitterIcon",
      "size": "18"
    }
  }), _c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "InstagramIcon",
      "size": "18"
    }
  }), _c('feather-icon', {
    staticClass: "ml-2",
    attrs: {
      "icon": "YoutubeIcon",
      "size": "18"
    }
  })], 1)])])])])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }