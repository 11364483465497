<template>
  <div class="mt-4 mb-4">
    <center>
      <span style="font-size: 3.5rem; color: black;" class="text-center">
        <b>Latihan Ujian</b>
      </span>
      <br />
      <span style="font-size: 3.5rem; color: black;" class="text-center">
        <b>
          Untuk
          <span class="text-primary">Hasil Berkualitas</span>
        </b>
      </span>
      <br />
      <b-img class="" :src="panah" alt="" width="auto" height="28px" />
      <h4 class="mt-3 container text-secondary" style="width: 80%;">
        Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, est.
        Fugiat aspernatur velit molestiae sint dolore, autem voluptatem
        provident non?
      </h4>
    </center>
  </div>
</template>

<script>
import {
  BCard,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BNav,
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BCard,
    BNav,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      img: require('@/assets/images/elp/ELP.png'),
      panah: require('@/assets/images/elp/panah melengkung.png'),
    }
  },
  props: {
    headerData: {
      type: Object,
      default: () => {},
    },
  },
}
</script>
