<template>
  <li class="li dropdown dropdown-right" v-if="userData">
    <a href="#">
      <b-avatar
        size="40"
        :src="userData.photo ? apiFile + userData.photo.file : null"
        variant="light-primary"
        badge
        class="badge-minimal"
        badge-variant="success"
      >
        <feather-icon v-if="!userData.name" icon="UserIcon" size="22" />
      </b-avatar>
    </a>
    <ul class="ul">
      <li class="li">
        <a @click="dashboard" class="text-primary">Dashboard</a>
      </li>
      <li class="li"><a @click="logout" class="text-primary">Logout</a></li>
    </ul>
  </li>
  <li v-else>
    <section class="d-flex align-items-center justify-content-center">
      <a
        class="btn btn-white mx-1"
        style="background: white; border: 1px solid #cdcdcd"
        @click="daftar"
      >
        Daftar
      </a>
      <a class="btn btn-primary" @click="masuk"> Masuk </a>
    </section>
  </li>
</template>

<script>
import {
  BNavItemDropdown,
  BDropdownItem,
  BDropdownDivider,
  BAvatar,
  BButton,
} from "bootstrap-vue";
import { initialAbility } from "@/libs/acl/config";
import useJwt from "@/auth/jwt/useJwt";
import { avatarText } from "@core/utils/filter";

export default {
  components: {
    BNavItemDropdown,
    BDropdownItem,
    BDropdownDivider,
    BAvatar,
    BButton,
  },
  data() {
    return {
      userData: JSON.parse(localStorage.getItem("userData")),
      avatarText,
    };
  },
  computed: {
    apiFile() {
      return "http://file.elp-dev.my.id/";
    },
  },
  methods: {
    dashboard() {
      if (this.userData.level.nama_level == "Siswa") {
        this.$router.push({ name: "statistik" });
      } else if (this.userData.level.nama_level == "Super Admin") {
        this.$router.push({ name: "admin-dashboard" });
      } else {
        this.$router.push({ name: "user-admin-dashboard" });
      }
    },
    masuk() {
      this.$router.push({ name: "auth-login" });
    },
    daftar() {
      this.$router.push({ name: "auth-register" });
    },
    logout() {
      localStorage.removeItem("token");

      // Remove userData from localStorage
      localStorage.removeItem("userData");

      localStorage.removeItem("myMenu");

      localStorage.removeItem("accessMenu");

      // Reset ability
      this.$ability.update(initialAbility);

      // Redirect to login page
      this.$router.push({ name: "auth-login" });
    },
  },
};
</script>
