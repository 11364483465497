var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-4 mb-4"
  }, [_c('center', [_c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "3.5rem",
      "color": "black"
    }
  }, [_c('b', [_vm._v("Latihan Ujian")])]), _c('br'), _c('span', {
    staticClass: "text-center",
    staticStyle: {
      "font-size": "3.5rem",
      "color": "black"
    }
  }, [_c('b', [_vm._v(" Untuk "), _c('span', {
    staticClass: "text-primary"
  }, [_vm._v("Hasil Berkualitas")])])]), _c('br'), _c('b-img', {
    attrs: {
      "src": _vm.panah,
      "alt": "",
      "width": "auto",
      "height": "28px"
    }
  }), _c('h4', {
    staticClass: "mt-3 container text-secondary",
    staticStyle: {
      "width": "80%"
    }
  }, [_vm._v(" Lorem ipsum dolor sit amet consectetur adipisicing elit. Rem, est. Fugiat aspernatur velit molestiae sint dolore, autem voluptatem provident non? ")])], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }