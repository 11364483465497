<template>
  <section class="mt-2">
    <b-card-group>
      <b-card no-body bg-variant="primary" text-variant="white">
        <div style="width: 90%" class="text-left mt-4 mb-4 container">
          <b-col>
            <b-row>
              <b-col md="6" xl="5" class="mb-2">
                <b-row class="mb-2 text-white" align-v="baseline">
                  <b-col cols="2" md="3">
                    <b-img :src="img" alt="" rounded width="80px" />
                  </b-col>
                  <b-col md="9">
                    <div style="font-size: 1.625em">
                      <b>Belajar.link</b>
                    </div>
                  </b-col>
                </b-row>
                <b-row class="mb-1 text-white">
                  <b-col>
                    <div style="font-size: 1em">
                      This card has supporting text below as a natural lead-in
                      to additional content.
                    </div>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" xl="2" class="mb-2">
                <div style="font-size: 1em" class="mb-2 text-white">
                  <b>Kontak</b>
                </div>
                <b-row class="mb-1">
                  <b-col>
                    <feather-icon icon="PhoneIcon" />
                    +62 8888 888 8888
                  </b-col>
                </b-row>
                <b-row>
                  <b-col>
                    <feather-icon icon="MailIcon" />
                    belajarlink@gmail.com
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" xl="2" class="mb-2">
                <!-- <b>Layanan & Informasi</b><br><br>
                            Tentang Kami<br>
                            Kebijakan Privasi<br>
                            Syarat & Ketentuan -->
                <div style="font-size: 1em" class="mb-2 text-white">
                  <b>Layanan & Informasi</b>
                </div>
                <b-row class="mb-1">
                  <b-col>
                    <b-link :to="{ name: 'tentang-kami' }" class="text-white">
                      Tentang Kami
                    </b-link>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <b-link
                      :to="{ name: 'kebijakan-privasi' }"
                      class="text-white"
                    >
                      Kebijakan Privasi
                    </b-link>
                  </b-col>
                </b-row>
                <b-row class="mb-1">
                  <b-col>
                    <b-link
                      :to="{ name: 'syarat-ketentuan' }"
                      class="text-white"
                    >
                      Syarat & Ketentuan
                    </b-link>
                  </b-col>
                </b-row>
              </b-col>
              <b-col md="6" xl="3" class="mb-2">
                <!-- <b>Alamat</b><br><br>
                            Jl. Belimbing No.13, ihapit, Kec. Bandung Wetan -->
                <div style="font-size: 1em" class="mb-2 text-white">
                  <b>Alamat</b>
                </div>
                <b-row class="mb-1">
                  <b-col>Jl. Belimbing No.13, ihapit, Kec. Bandung Wetan</b-col>
                </b-row>
              </b-col>
            </b-row>
          </b-col>
        </div>
        <!-- <table width="100%" valign="top">
                    <tr valign="top">
                        <td width="5%">
                        </td>
                        <td width="30%">
                            <b-img :src=img alt="" rounded width="62px" />
                            <b>Einstein Learning Plus</b><br><br>
                            This card has supporting text below as a natural lead-in to additional content.
                        </td>
                        <td width="3%">
                        </td>
                        <td width="13%">
                            <b>Kontak</b><br><br>
                            <feather-icon icon="PhoneIcon" /> +62 8888 888 8888<br>
                            <feather-icon icon="MailIcon" /> einstein@gmail.com
                        </td>
                        <td width="3%">
                        </td>
                        <td width="18%">
                            <b>Layanan & Informasi</b><br><br>
                            Tentang Kami<br>
                            Kebijakan Privasi<br>
                            Syarat & Ketentuan
                        </td>
                        <td width="3%">
                        </td>
                        <td width="20%">
                            <b>Alamat</b><br><br>
                            Jl. Belimbing No.13, ihapit, Kec. Bandung Wetan
                        </td>
                        <td width="5%">
                        </td>
                    </tr>
                </table> -->
        <!-- <b-card-text>
            Some quick example text to build on the card title and make up.
          </b-card-text> -->
      </b-card>
      <!-- </b-col> -->
    </b-card-group>
    <!-- <b-col> -->
    <div class="bg-white p-2">
      <div class="container" style="width: 90%">
        <div class="row">
          <div class="col-lg-9">
            <h6 class="text-left">
              <b>© {{ new Date().getFullYear() }} Belajar.link</b>
              <span class="d-none d-sm-inline-block">
                . All rights Reserved
              </span>
            </h6>
          </div>
          <div class="col-lg d-none d-md-block d-md-none d-lg-block">
            <h6 class="text-left">
              <b>Ikuti Kami</b>
              <feather-icon class="ml-2" icon="FacebookIcon" size="18" />
              <feather-icon class="ml-2" icon="TwitterIcon" size="18" />
              <feather-icon class="ml-2" icon="InstagramIcon" size="18" />
              <feather-icon class="ml-2" icon="YoutubeIcon" size="18" />
            </h6>
          </div>
        </div>
      </div>
    </div>
    <!-- </b-col> -->
  </section>
</template>

<script>
import { BLink } from "bootstrap-vue";
import {
  BRow,
  BCol,
  BCardGroup,
  BCard,
  BCardText,
  BCardFooter,
  BCardBody,
  BCardTitle,
  BImg,
  BNavbar,
  BNavbarToggle,
  BCollapse,
  BTabs,
  BNavItem,
  BButton,
  BTable,
  BFormGroup,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";

export default {
  components: {
    BLink,
    BTable,
    BFormGroup,
    BRow,
    BCol,
    BCardGroup,
    BCard,
    BCardText,
    BCardFooter,
    BCardBody,
    BCardTitle,
    BTabs,
    BButton,
    BNavItem,
    BNavbar,
    BNavbarToggle,
    BCollapse,
    BImg,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      fields: ["first_name", "last_name", "age"],
      items: [],
      img: require("@/assets/images/elp/belajar.png"),
    };
  },
};
</script>

<style>
@media (max-width: 1180px) {
  .hide-tablet {
    display: none;
  }
}
</style>
