<template>
  <section id="card-images">
    <br />
    <h3 class="mt-3 mb-2 text-primary" align="center" text-color="primary">
      <b>Kata Mereka</b>
    </h3>
    <center>
      <h4 align="center" text-color="primary" class="mb-2" style="width: 80%;">
        Ulasan dari Para Alumni yang Sukses Setelah Mengikuti Try Out di ELP
      </h4>
    </center>
    <!-- <h4 align="center" text-color="primary">Mengikuti Try Out di ELP</h4> -->
    <br />
    <center>
      <div class="" style="width: 85%;">
        <swiper
          class="swiper-responsive-breakpoints"
          :options="swiperOptions"
          :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
        >
          <swiper-slide v-for="(data, index) in swiperData" :key="index">
            <b-card>
              <b-card-body>
                <center>
                  <b-avatar size="45" class="mb-2" />
                  <h6 class="font-weight-bolder mb-25">
                    Kidam Kusnandi
                  </h6>
                  <span class="">SMK Assalaam</span>
                </center>
                <!-- <b-media no-body class="row">
                  <b-media-aside class="mr-75 col-md-4">
                    <center>
                      <b-avatar size="45" class="mb-2" />
                    </center>
                  </b-media-aside>
                  <b-media-body class="col-md text-left">
                    <h6 class="font-weight-bolder mb-25 text-left">
                      Kidam Kusnandi
                    </h6>
                    <b-card-text class="text-left">SMK Assalaam</b-card-text>
                  </b-media-body>
                </b-media> -->

                <b-card-text class="mt-1 text-left mt-2">
                  Lulus Ujian CPNS
                </b-card-text>
                <b-form-rating
                  class="float-left"
                  value="5"
                  no-border
                  readonly
                  variant="warning"
                  inline
                  size="sm"
                />
                <br />

                <b-card-text class="mt-1 text-left">
                  Some quick example text to build on the card title and make up
                  the bulk of the card's content.
                </b-card-text>
              </b-card-body>
            </b-card>
          </swiper-slide>
          <div slot="pagination" class="swiper-pagination mt-4" />
        </swiper>
      </div>
    </center>
    <!-- <b-col class="mt-4"> -->
    <!-- <b-card > -->
    <!-- <br />
      <h3 class="mt-3 mb-2 text-primary" align="center" text-color="primary">Kata Mereka</h3>
      <h4 align="center" text-color="primary">Ulasan dari Para Alumni yang Sukses Setelah</h4>
      <h4 align="center" text-color="primary">Mengikuti Try Out di ELP</h4> -->
    <!-- <b-carousel id="carousel-interval" controls indicators :interval="400" class="mb-2 ml-2 mr-2">
        <b-carousel-slide :img-src="require('@/assets/images/elp/kata mereka.png')" />
      </b-carousel> -->
    <!-- <b-carousel class="mt-4" indicators>
        <b-carousel-slide v-for="(slide, i) in slides" :key="i">
          <template v-slot:img>
            <b-row>
              <b-col cols="12" md="4" class="mb-3" v-for="(item, index) in slide" :key="index">
                <b-card>
                  <b-card-body>
                    <b-media no-body>
                      <b-media-aside class="mr-75">
                        <b-avatar size="45" />
                      </b-media-aside>
                      <b-media-body>
                        <h6 class="font-weight-bolder mb-25">Kidam Kusnandi</h6>
                        <b-card-text>SMK Assalaam</b-card-text>
                      </b-media-body>
                    </b-media>

                    <b-card-text class="mt-1"> Lulus Ujian CPNS</b-card-text>
                    <b-form-rating value="5" no-border readonly variant="warning" inline size="sm" />
                    <b-card-text class="mt-1"> Some quick example text to build on the card title and make up the bulk of the card's content. </b-card-text>
                  </b-card-body>
                </b-card>
              </b-col>
            </b-row>
          </template>
        </b-carousel-slide>
      </b-carousel>
    </b-col> -->
  </section>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { Swiper, SwiperSlide } from 'vue-awesome-swiper'
import { BImg } from 'bootstrap-vue'
import 'swiper/css/swiper.css'
import { codeResponsive } from './code'
import {
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardBody,
  BCardTitle,
  BCardText,
  BCardGroup,
  BButton,
  BCarousel,
  BCarouselSlide,
  BMedia,
  BMediaAside,
  BMediaBody,
  BAvatar,
  BFormRating,
} from 'bootstrap-vue'

export default {
  components: {
    Swiper,
    SwiperSlide,
    BCardCode,
    BImg,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardBody,
    BCardTitle,
    BCardText,
    BCardGroup,
    BButton,
    BCarousel,
    BCarouselSlide,
    BMedia,
    BMediaAside,
    BMediaBody,
    BAvatar,
    BFormRating,
  },
  data() {
    return {
      codeResponsive,

      /* eslint-disable global-require */
      swiperData: [
        { img: require('@/assets/images/banner/banner-30.jpg') },
        { img: require('@/assets/images/banner/banner-31.jpg') },
        { img: require('@/assets/images/banner/banner-32.jpg') },
        { img: require('@/assets/images/banner/banner-33.jpg') },
        { img: require('@/assets/images/banner/banner-34.jpg') },
        { img: require('@/assets/images/banner/banner-35.jpg') },
      ],
      /* eslint-disable global-require */

      swiperOptions: {
        slidesPerView: 5,
        spaceBetween: 50,
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        breakpoints: {
          768: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          320: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
        },
      },
    }
  },
}
</script>

<style>
.carousel-indicators > li {
  background-color: var(--primary);
}
</style>
